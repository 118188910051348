<div class="center-20">
    <div fxLayout="row" fxLayoutAlign="space-around center" class="spinner" *ngIf="!spinner">
        <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
    </div>
    <div *ngIf="spinner">
        <h2 fxLayoutAlign="center center"> {{ 'ayuda.titulo' | transloco }} </h2>
        <mat-list>
            <mat-card class="full-width" fxFlex>
                <mat-list-item>
                    <a href="https://ppc-static.s3.amazonaws.com/manual/ppc-manual.pdf" target="_blank" >
                        <span> {{ 'ayuda.centro' | transloco }} </span>
                    </a>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <a href="https://wa.me/send?phone=5491144969912&amp;text=Hola,%20quiero%20conocer%20más%20sobre%20condor%20solutions"
                        target="_blank" target="_blank">
                        <span> {{ 'ayuda.contacto' | transloco }} </span>
                    </a>                   
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span>Portal Condor v{{sistFront.version}}</span>
                </mat-list-item>
            </mat-card>
        </mat-list>
    </div>
</div>
