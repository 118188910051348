<ng-container *transloco="let t; read: 'ordenes-compra'">
  <div mat-dialog-content>{{ t("confirmar-desaut") }}</div>
  <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 24px">
    <button mat-raised-button (click)="cancel()" style="margin-right: 8%">
      {{ t("cancelar") }}
    </button>
    <button mat-raised-button (click)="accept()" color="primary">
      {{ t("aceptar") }}
    </button>
  </div>
</ng-container>
