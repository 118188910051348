<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 1 : 0"
    [hasBackdrop]="mobileQuery.matches ? true : false"
  >
    <mat-sidenav
      #sidenav
      style="width: 235px"
      [mode]="mobileQuery.matches ? 'over' : 'over'"
      [fixedInViewport]="mobileQuery.matches"
    >
      <mat-nav-list>
        <!-- <div fxLayoutAlign="center" style="width: 80px;">
          <img src="https://ordserp19.open.com.ar/erp19/ppcdr/img/emp/{{idimagenemp}}" class="size-logo">
        </div> -->
        <div fxLayoutAlign="center">
          <img
            class="size-logo"
            (click)="toDashboard()"
            style="cursor: pointer"
            src="../../../assets/images/LightEnterprise.png"
          />
        </div>
        <!-- <div fxLayoutAlign="center">
            <h5>{{ (descprov?.length>20)? (descprov | slice:0:20)+'...':(descprov) }}</h5>
          </div> -->
        <!-- Menú general -->
        <div *ngIf="route === 'Auth'">
          <ng-container *transloco="let e; read: 'label-nav'">
            <ng-container *transloco="let t; read: 'sidenav-nav'">
              <!-- SELECT EMPRESAS -->
              <div style="padding-left: 25px">
                <h4>{{ t("autorizaciones") }}</h4>
              </div>
              <div>
                <a
                  *ngFor="let route of userRoutes"
                  mat-list-item
                  routerLinkActive="active"
                  [routerLink]="route.path"
                >
                  <mat-icon
                    aria-hidden="false"
                    class="iconos"
                    aria-label="Example home icon"
                    >{{ route.icon }}</mat-icon
                  >
                  &nbsp;&nbsp; {{ e(route.label) }}
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <!-- <div fxLayoutAlign="center">
            <h5>{{ (descprov?.length>20)? (descprov | slice:0:20)+'...':(descprov) }}</h5>
          </div> -->
        <!-- Menú general -->
        <div *ngIf="route === 'Facturas'">
          <ng-container *transloco="let e; read: 'label-nav'">
            <ng-container *transloco="let t; read: 'sidenav-nav'">
              <!-- SELECT EMPRESAS -->
              <div style="padding-left: 25px">
                <h4>{{ t("facturas") }}</h4>
              </div>
              <div>
                <a
                  *ngFor="let route of nanduRoutes"
                  mat-list-item
                  routerLinkActive="active"
                  [routerLink]="route.path"
                >
                  <mat-icon
                    aria-hidden="false"
                    class="iconos"
                    aria-label="Example home icon"
                    >{{ route.icon }}</mat-icon
                  >
                  &nbsp;&nbsp; {{ e(route.label) }}
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar
        *ngIf="
          nameURL != '/login' &&
          nameURL != '/olvido' &&
          nameURLs != '/verificar' &&
          nameURLs != '/'
        "
      >
        <!-- BOTON MENU -->
        <div
          *ngIf="
            nameURL != '/inicio' &&
            nameURL != '/settings' &&
            nameURL != '/ayuda'
          "
        >
          <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>

        <div style="display: flex; flex-direction: row; margin-left: auto">
          <!-- DESPLEGABLE USUARIO -->
          <div
            fxLayout="row"
            fxLayoutAlign="end center"
            style="margin-left: auto"
            style="cursor: pointer"
          >
            <ng-container *transloco="let translate; read: 'sidenav-profile'">
              <ngx-avatar
                fxLayout="row"
                fxLayoutAlign="space-evenly center"
                size="50"
                name="{{ nomcomp }}"
                [matMenuTriggerFor]="matMenu"
              >
              </ngx-avatar>
              <mat-menu #matMenu="matMenu" xPosition="after">
                <div class="item nombre">
                  <b>{{ nomcomp }}</b>
                </div>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="toDashboard()">
                  <mat-icon>apps</mat-icon>
                  <span>{{ translate("application") }}</span>
                </button>
                <button mat-menu-item (click)="toSettings()">
                  <mat-icon>settings</mat-icon>
                  <span>{{ translate("settings") }}</span>
                </button>
                <button mat-menu-item (click)="toHelp()">
                  <mat-icon>help_outline</mat-icon>
                  <span>{{ translate("help") }}</span>
                </button>
                <button mat-menu-item (click)="logout()">
                  <mat-icon>logout</mat-icon>
                  <span>{{ translate("logout") }}</span>
                </button>
              </mat-menu>
            </ng-container>
          </div>
        </div>
      </mat-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
