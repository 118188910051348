import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  public url = environment.baseUrl;
  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}

  login(username: string, password: string) {
    let loginDetails = {
      username: username,
      password: password,
    };

    return this.http.post<any>(this.url + 'autlogin/login/', loginDetails).pipe(
      map((user) => {
        // Login successful if there's a JWT token in the response
        if (user && user.token) {
          let token = {
            token: user.token,
          };
          const currentUser = JSON.stringify(token.token);
          localStorage.setItem('currentUser', currentUser);
          localStorage.setItem('iduserpcdr', user.iduserpcdr);
          localStorage.setItem('descprov', user.descprov);
          localStorage.setItem('nomcomp', user.nomcomp);
          localStorage.setItem('idimagenemp', user.idimagenemp);
        }
        return user;
      })
    );
  }

  logout() {
    this.sidenav?.close();
    this.router.navigate(['/login']);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('iduserpcdr');
    localStorage.clear();
  }

  validate(body) {
    let pass = {
      iduser: localStorage.getItem('iduserpcdr'),
      newpass: body.password,
    };

    return this.http.put<any>(this.url + 'autlogin/login/resetpass', pass).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
