<div fxLayout="column" fxFill>
  <ng-container *transloco="let t; read: 'respuestas'">
    <div class="title-dialog">{{ t("op") }}</div>
    <div *ngFor="let res of data">
      <div fxLayout="row" fxLayoutAlign="start center" class="style-res">
        <mat-icon class="icon-res" fontSet="material-icons-outlined">description</mat-icon>
        <div class="txt-res">{{ res }}</div>
      </div>
      <div *ngIf="data.length >= 2">
        <mat-divider></mat-divider>
      </div>
    </div>
    <div fxLayout="row" class="buttons-res" fxLayoutAlign="end end">
      <button class="btn-mobile" mat-raised-button (click)="cancel()" color="primary">{{ t("aceptar") }}</button>
    </div>
  </ng-container>
</div>