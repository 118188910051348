import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import * as coord_open from '../nandu/coord/open.json';
import * as coord_personal from '../nandu/coord/personal.json';
import * as coord_generic from '../nandu/coord/generic.json';
import jsQR from 'jsqr';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { DialogInitService } from '../services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ConceptoComponent } from './concepto/concepto';
export interface Factura {
  cuit: string;
  fecha: string;
  factura: string;
  total: string;
  codCae: string;
  numero: string;
}

@Component({
  selector: 'app-nandu',
  templateUrl: './nandu.component.html',
  styleUrls: ['./nandu.component.scss'],
})
export class NanduComponent implements OnInit {
  fileUpload;
  fileSelected: boolean = false;
  load: boolean = false;
  loadQR: boolean = false;
  facturaA: boolean = false;
  noQR: boolean = false;
  textContent;
  namesFile;
  coord_open: any = coord_open as any;
  coord_personal: any = coord_personal as any;
  coord_generic: any = coord_generic as any;
  facturaCoord = [];
  displayedColumns;
  displayedColumnsQR = ['cuit', 'fecha', 'factura','codCae', 'numero','total'];
  pdfs = [];
  pdfsQR = [];
  textRendered;
  dataSource: MatTableDataSource<AbstractControl>;
  dataQR: MatTableDataSource<AbstractControl>;
  formGroup: FormGroup;
  formGroupQR: FormGroup;
  traducciones: string[];
  get factControlArray() {
    return this.formGroup.get('facturas') as FormArray;
  }
  get factControlArrayQR() {
    return this.formGroupQR.get('facturasQR') as FormArray;
  }


  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private formBuilderQR: FormBuilder,
    public snackBar: MatSnackBar,
    public translateService: TranslocoService,
    public dialogInitService: DialogInitService,
    public dialog: MatDialog) {
    this.coord_open = this.coord_open.default;
    this.coord_personal = this.coord_personal.default;
    this.coord_generic = this.coord_generic.default;
  }

  ngOnInit(): void {
    this.generarTraducciones();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      facturas: this.formBuilder.array([]),
    });
  }

  createFormQR() {
    this.formGroupQR = this.formBuilder.group({
      facturasQR: this.formBuilderQR.array([]),
    });
  }

  searchInput: string;
  onSearchInputValueEmitted(value: string) {
    this.searchInput = value;
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
  }

  onChange(event) {
    this.namesFile = [];
    this.load = false;
    this.loadQR = false;
    this.createForm();
    this.createFormQR();

    if (event.target.files && event.target.files[0]) {
      this.fileSelected = true;
      const files = Array.from(event.target.files);
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.pdfs.push(event.target.result);
        };
        reader.readAsDataURL(event.target.files[i]);
        this.namesFile.push(files[i]);
      }
    } else {
      this.fileSelected = false;
    }
  }

  public afterLoadComplete(e: PDFDocumentProxy) {
    const p = e.getPage(1).then((page) => {
      page.getTextContent().then((textContent) => {
        this.textContent = textContent.items;
        this.matchCUIT(this.textContent);
      });
    });
  }

  matchCUIT(text) {
    const arrays = {
      cuit: [],
    };
    let cuit;
    for (let i = 0; i < text.length; i++) {
      const { str } = text[i];
      if (this.cuitRegex.test(str)) {
        arrays.cuit.push(text[i]);
        cuit = this.selection(arrays.cuit, 1000, 'top');
        cuit = this.cleanCUITInput(cuit);
      }
    }

    //cuits
    const personal = '30639453738';
    const open = '30650208834';
    switch (cuit) {
      case personal:
        this.asignCoord(this.coord_personal, text, cuit, true);
        break;
      case open:
        this.asignCoord(this.coord_open, text, cuit, true);
        break;
      default:
        this.asignCoord(this.coord_generic, text, cuit, false);
        break;
    }

  }

  asignCoord(json, text, cuit, A) {

    if (A) {
      const coordenadas = {
        xFecha: json[0].fecha_em,
        xTotal: json[0].total,
        xSub: json[0].subtotal,
        xIva: json[0].iva,
        xCae: json[0].cae,
        xTipo: json[0].tipo_fac,
        xNro: json[0].numero,
        xStart: json[0].concepto.start,
        xEnd: json[0].concepto.end,
      }
      this.displayedColumns = ['cuit', 'fecha', 'factura', 'codCae', 'numero', 'subtotal', 'iva', 'total', 'concepto'];
      this.filter(coordenadas, text, cuit, true);
    } else {
      const coordenadas = {
        xFecha: json[0].fecha_em,
        xTotal: json[0].total,
        xCae: json[0].cae,
        xTipo: json[0].tipo_fac,
        xNro: json[0].numero,
      }
      this.displayedColumns = ['cuit', 'fecha', 'factura', 'codCae', 'numero', 'total',];
      this.filter(coordenadas, text, cuit, false);
    }

  }

  //regex
  cuitRegex = /\b(20|23|24|27|30|33|34)[-/]?([0-9]{8})[-/]?([0-9])\b/g;
  fechaRegex = /\b([0-2][0-9]|(3)[0-1])(\/|\.)(((0)[0-9])|((1)[0-2]))(\/|\.)\d{4}$/g;
  tipoFacturaRegex = /^[ABC]$/;
  totalRegex = /[0-9]*\.[0-9]+,[0-9]+|([0-9]+(,[0-9]+)+)\.[0-9]+|[0-9]+,[0-9]+/igm;
  caeRegex = /\b\d{14}\b/g;
  nroRegex = /([0-9]{4,5}\s*-\s*[0-9]{8})/gm;
  

  filter(coordenadas, text, cuit, showSubtotalIva) {
    const arrays = {
      total: [],
      fecha: [],
      cuit: [],
      cae: [],
      fac: [],
      nro: [],
      concepto: []
    };
    let xTotal = coordenadas.xTotal,
      xFecha = coordenadas.xFecha,
      xCae = coordenadas.xCae,
      xTipo = coordenadas.xTipo,
      xNro = coordenadas.xNro,
      xSub = coordenadas.xSub,
      xIva = coordenadas.xIva,
      xStart = coordenadas.xStart,
      xEnd = coordenadas.xEnd;

    let factura, codCae, total, fecha, numero, iva, subtotal;
    const results = [];

    for (let i = 0; i < text.length; i++) {
      const { str } = text[i];


      if (this.fechaRegex.test(str)) {
        arrays.fecha.push(text[i]);
      }
      if (this.tipoFacturaRegex.test(str)) {
        arrays.fac.push(text[i]);
      }
      if (this.totalRegex.test(str)) {
        arrays.total.push(text[i]);
      }
      if (this.caeRegex.test(str)) {
        arrays.cae.push(text[i]);
      }
      if (this.nroRegex.test(str)) {
        arrays.nro.push(text[i]);
      }

      //segunda verificación de importes
      const num = str.match(/\b\d+(?:,\d+)*(?:\.\d+)?\b/);
      if (num && num[0] && !arrays.total.includes(num[0])) {
        arrays.total.push(text[i]);
      }

      //conceptos
      if (text[i].transform[5] >= xEnd && text[i].transform[5] <= xStart) {
        arrays.concepto.push(text[i]);
      }
    }

    let concepto = arrays.concepto.sort((a, b) => a.transform[5] - b.transform[5]);
    let conceptoSTR = [];
    for (let index = 0; index < concepto.length; index++) {
      conceptoSTR.push(concepto[index].str);
    }


    fecha = this.cleanDateInput(this.selection(arrays.fecha, xFecha, 'top'));
    factura = this.selection(arrays.fac, xTipo, 'top');
    codCae = this.cleanCAEInput(this.selection(arrays.cae, xCae, 'bottom'));
    numero = this.cleanInvoiceNumberInput(this.selection(arrays.nro, xNro, 'top'));


    if (showSubtotalIva) {
      [subtotal, iva, total] = this.selectionSubtotalIva(arrays.total, xSub, xIva, xTotal);
      results.push([cuit, fecha, factura, iva, subtotal, total, codCae, numero, conceptoSTR]);
    } else {
      total = this.cleanMoneyInput(this.selection(arrays.total, xTotal, 'top'));
      results.push([cuit, fecha, factura, total, codCae, numero]);
    }

    this.toTable(results, showSubtotalIva);
  }

  selection(array, x, position): any {
    let maxPosition = position === 'top' ? -Infinity : Infinity;
    let match = '';

    array.forEach(item => {
      const positionValue = item.transform[5];
      if (positionValue < x) {
        if (position === 'top' && positionValue > maxPosition) {
          maxPosition = positionValue;
          match = item.str;
        } else if (position === 'bottom' && positionValue < maxPosition) {
          maxPosition = positionValue;
          match = item.str;
        }
      }
    });

    return match;
  }

  selectionSubtotalIva(arrays, xSub, xIva, xTotal) {
    const array = {
      total: [],
      subtotal: [],
      iva: [],
    };

    let subtotal, iva, total;

    arrays.forEach(item => {
      const positionValue = item.transform[5];
      const str = item.str;
      if (positionValue <= xSub && positionValue > xIva) {
        array.subtotal.push(item);
        subtotal = this.cleanMoneyInput(this.closestObj(array.subtotal, xSub));
      }
      if (positionValue <= xIva && positionValue > xTotal) {
        array.iva.push(item);
        iva = this.closestObj(array.iva, xIva);
      }
      if (positionValue <= xTotal) {
        array.total.push(item);
        total = this.cleanMoneyInput(this.closestObj(array.total, xTotal));
      }
    });
    return [subtotal, iva, total];
  }

  closestObj(array, targetValue) {
    const closestObj = array.reduce((prev, curr) => {
      const currDist = Math.abs(curr.transform[5] - targetValue);
      const prevDist = Math.abs(prev.transform[5] - targetValue);
      return currDist < prevDist ? curr : prev;
    });
    return closestObj.str;
  }



  toTable(results, flag) {
    const rows = this.factControlArray;

    if (flag) {
      for (let index = 0; index < results.length; index++) {
        rows.push(
          this.formBuilder.group({
            cuit: [results[index][0]],
            fecha: [results[index][1]],
            factura: [results[index][2]],
            iva: [results[index][3]],
            subtotal: [results[index][4]],
            total: [results[index][5]],
            codCae: [results[index][6]],
            numero: [results[index][7]],
            concepto : [results[index][8]],
          })
        );
      }
    } else {
      for (let index = 0; index < results.length; index++) {
        rows.push(
          this.formBuilder.group({
            cuit: [results[index][0]],
            fecha: [results[index][1]],
            factura: [results[index][2]],
            total: [results[index][3]],
            codCae: [results[index][4]],
            numero: [results[index][5]],
          })
        );
      }
    }

    this.load = true;
    this.dataSource = new MatTableDataSource(
      this.factControlArray.controls);
  }


  //Regex para limpiar valores encontrados
  cleanCUITInput(input: string | null | undefined): string | null {
    if (typeof input !== 'string') {
      return null;
    }
    const cleanInput = input.replace(/[^0-9]/g, '');
    return cleanInput.length > 0 ? cleanInput : null;
  }

  cleanDateInput(input: string | null | undefined): string | null {
    if (typeof input !== 'string') {
      return null;
    }
    const cleanInput = input.replace(/[^0-9\/.\-]/g, '').replace(/(^[\-./]+)|([\-.\/]+$)/g, '');
    return cleanInput.length > 0 ? cleanInput : null;
  }

  cleanCAEInput(input: string | null | undefined): string | null {
    if (typeof input !== 'string') {
      return null;
    }

    const cleanInput = input.replace(/[^0-9]/g, '');
    const limitInput = cleanInput.substring(0, 14);

    return limitInput.length > 0 ? limitInput : null;
  }

  cleanInvoiceNumberInput(input: string | null | undefined): string | null {
    if (typeof input !== 'string') {
      return null;
    }
    const cleanInput = input.replace(/[^0-9\-]/g, '');
    return cleanInput.length > 0 ? cleanInput : null;
  }


  cleanMoneyInput(input: string | null | undefined): string | null {
    if (typeof input !== 'string') {
      return null;
    }
    const cleanInput = input.replace(/[^0-9.,]/g, '').replace(/,[^0-9]*$/, '');
    return cleanInput.length > 0 ? cleanInput : null;
  }

  openConcepto(row: FormGroup) {
    const concepto = row.get('concepto').value;
    const dialogRef = this.dialog.open(ConceptoComponent, {
      data: row.value
    });
}


  //Data from QR 
  onPdfLoadComplete(pdf: PDFDocumentProxy) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    pdf.getPage(1).then(page => {
      const viewport = page.getViewport({ scale: 3.0 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: ctx,
        viewport: viewport
      };

      page.render(renderContext).promise.then(() => {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert'
        });
        if (code) {
          try {
            const url = new URL(code.data);
            const encodedString = url.searchParams.get('p');
            const decodedString = atob(encodedString);
            const decodedObject = JSON.parse(decodedString);
            this.QrtoTable(decodedObject);
          } catch (e) {
            this.snackBar.open(this.traducciones['errores'].noFound, 'X', {
              duration: 4000,
            });
            this.emptyTable();
          }
        } else {
          this.snackBar.open(this.traducciones['errores'].noFound, 'X', {
            duration: 4000,
          });
          this.emptyTable();
        }
      });
    });
  }

  QrtoTable(decodedObject) {
    const rows = this.factControlArrayQR;
    let tipo;
    switch (decodedObject.tipoCmp) {
      case 1:
        tipo = 'A';
        break;
      case 6:
        tipo = 'B';
        break;
      case 11:
        tipo = 'C';
        break;
      default:
        tipo = 'desconocido';
    }


    rows.push(
      this.formBuilderQR.group({
        cuit: [decodedObject.cuit],
        fecha: [this.formatDate(decodedObject.fecha)],
        factura: [tipo],
        total: [decodedObject.importe],
        codCae: [decodedObject.codAut],
        numero: [this.formatNumeroComprobante(decodedObject.ptoVta, decodedObject.nroCmp)]
      })
    );


    this.loadQR = true;
    this.dataQR = new MatTableDataSource(
      this.factControlArrayQR.controls);
  }

  emptyTable() {
    const rows = this.factControlArrayQR;
    const noData = '';


    rows.push(
      this.formBuilderQR.group({
        cuit: [noData],
        fecha: [noData],
        factura: [noData],
        total: [noData],
        codCae: [noData],
        numero: [noData]
      })
    );


    this.loadQR = true;
    this.dataQR = new MatTableDataSource(
      this.factControlArrayQR.controls);
  }

  formatDate(date: string): string {
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(date, 'dd/MM/yyyy');
    return formattedDate || '';
  }

  formatNumeroComprobante(parte1: number, parte2: number): string {
    const parte1Str = parte1.toString().padStart(4, '0');
    const parte2Str = parte2.toString().padStart(8, '0');
    const nroFormateado = `${parte1Str}-${parte2Str}`;
    return nroFormateado;
  }

  generarTraducciones() {
    this.translateService
      .selectTranslateObject('lector-facturas')
      .subscribe((traduccion) => {
        this.traducciones = traduccion;
      });
  }
}
