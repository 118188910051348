import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
// import { ActivadorComponent } from './activador/activador.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { DashboardComponent } from './dashboard/dashboard.component';
export const routes = [
  {
    path: 'inicio',
    component: DashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'ayuda',
    component: AyudaComponent,
    pathMatch: 'full',
  },
  {
    path: 'settings',
    component: ConfiguracionesComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RoutingModule {}

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules, // Comment to allow lazy loading
  onSameUrlNavigation: 'ignore',
  // useHash: true // Comment to avoid # prefix on the URL
});
