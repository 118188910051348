import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-respuesta',
  templateUrl: './respuesta.component.html',
  styleUrls: ['../pago.component.scss'],
})
export class RespuestaComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RespuestaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if(screen.width < 800){
      this.dialogRef.updateSize('80%', '90%');
    } else {
      this.dialogRef.updateSize('40%', '65%');
    }
  }

  cancel(): void {
    this.dialogRef.close({ data: false });
  }
}
