<body style="background-image: linear-gradient(to right bottom, #aaaaaa, #b7b7b7, #c4c4c4, #d2d2d2, #dfdfdf);">
    <div class="login-container">
        <mat-card fxLayout="column" fxLayoutAlign="space-between center">
           <div *ngIf="componentCssClass=='dark-theme'">
                <img src="../../assets/images/LogoDark-Enterprise.png" class="logo-condor">
            </div>
            <div *ngIf="componentCssClass!='dark-theme'">
                <img src="../../assets/images/LightEnterprise.png" class="logo-condor">
            </div>
            <form [formGroup]="loginForm" #formDirective="ngForm" (ngSubmit)="onSubmit()" style="margin-bottom: 15px">
                <mat-form-field class="login-inputs">
                    <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color credentials-margin">person
                    </mat-icon>
                    <input matInput autocomplete="off" placeholder="Usuario" formControlName="username"
                        [maxLength]="40">
                    <mat-error *ngIf="loginForm.controls['username'].errors?.required">
                        Ingresa un usuario
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="login-inputs">
                    <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color credentials-margin">lock
                    </mat-icon>
                    <input matInput autocomplete="off" type="password" placeholder="Clave" formControlName="password"
                        [maxLength]="40" [type]="hide ? 'password' : 'text'">
                    <mat-error style="text-align:center;" *ngIf="invalidCredentials">
                        Usuario o clave incorrectos. Por favor intente nuevamente
                    </mat-error>
                    <mat-icon matTooltip="{{ ( hide ? 'Mostrar' : 'Ocultar' ) }}" class="clickable" matSuffix
                        (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <div id="spinner-placeholder" *ngIf="!checkingUserCredentials"> <br></div>
                <div fxLayout="row" style="margin-bottom: auto; margin-top: auto;" fxLayoutAlign="space-around center"
                    *ngIf="checkingUserCredentials">
                    <mat-spinner diameter="45" strokeWidth="5"></mat-spinner>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-around" style="margin: auto 25px;">
                    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="checkingUserCredentials"
                        style="margin-top: 20px">
                        Ingresar
                    </button>
                    <a mat-button (click)="olvido()" style="margin-top: 10px;">Olvidé
                        mi clave</a>
                </div>
            </form>
        </mat-card>
    </div>
</body>