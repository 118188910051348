import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['../pago.component.scss']
})
export class ConfirmarComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<ConfirmarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }

  accept() {
    this.dialogRef.close({ data: true })
  }

 cancel(): void {
    this.dialogRef.close({ data: false });
  }

}
