<div class="center-table">
  <ng-container *transloco="let t; read: 'lector-facturas'">
    <h3 class="table-header"> {{ t("titulo") }}</h3>
    <br /><br />

    <div fxLayout="row" fxLayoutAlign="start center">
      <label for="fileUpload">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div style="margin-right: 4%">
            <button mat-raised-button color="primary" (click)="fileInput.click()">
              <mat-icon [inline]="true">file_upload</mat-icon>
              {{ t("archivos") }}
            </button>
          </div>
          <span *ngIf="!fileSelected" class="text-input">{{ t("input") }}</span>

          <span *ngIf="fileSelected" class="text-input">
            <div *ngFor="let item of namesFile">
              {{ item.name }}
            </div>
          </span>
        </div>
        <input id="fileUpload" multiple="multiple" (change)="onChange($event)" accept=".pdf" style="display: none"
          #fileInput type="file" />
      </label>

    </div>


    <div style="height: 0; width: 0;" *ngFor="let item of pdfs">
      <pdf-viewer [src]="item" [render-text]="true" (after-load-complete)="afterLoadComplete($event)"></pdf-viewer>
    </div>


    <br />
    <br>
    <div *ngIf="load">
      <h3 class="table-header">{{ t("lector") }}</h3>
      <form [formGroup]="formGroup">
        <mat-table #table id="dataSourceGrid" [dataSource]="dataSource">
          <mat-header-row *matHeaderRowDef=" displayedColumns"></mat-header-row>
          <ng-container matColumnDef="cuit">
            <mat-header-cell *matHeaderCellDef>CUIT</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="cuit" id="cuit_{{i}}" name="cuit_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <mat-header-cell *matHeaderCellDef>{{ t("fecha-emision") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="fecha" id="fecha_{{i}}" name="fecha_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="factura">
            <mat-header-cell *matHeaderCellDef>{{ t("tipo") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="factura" id="factura_{{i}}" name="factura_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="iva" >
            <mat-header-cell *matHeaderCellDef >IVA</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row" >
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="iva" id="iva_{{i}}" name="iva_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="subtotal" >
            <mat-header-cell *matHeaderCellDef >{{ t("subtotal") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" >
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="subtotal" id="subtotal_{{i}}" name="subtotal_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="total">
            <mat-header-cell *matHeaderCellDef>{{ t("total") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="total" id="total_{{i}}" name="total_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="codCae">
            <mat-header-cell *matHeaderCellDef>CAE</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="codCae" id="codCae_{{i}}" name="codCae_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="numero">
            <mat-header-cell *matHeaderCellDef>{{ t("numero") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="numero" id="numero_{{i}}" name="numero_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="concepto">
            <mat-header-cell *matHeaderCellDef>Concepto</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <button mat-icon-button (click)="openConcepto(row)" id="concepto_{{i}}" name="concepto_{{i}}">
                  <mat-icon class="material-symbols-outlined" color="primary">description</mat-icon>
                </button>
              </ng-container>
            </mat-cell>
          </ng-container>
          
          
          <mat-row *matRowDef="let row; columns:  displayedColumns"></mat-row>
        </mat-table>
      </form>
    </div>

    <!--  QR TABLE -->


    <div *ngFor="let item of pdfs" style="height: 0; width: 0;">
      <pdf-viewer [src]="item" [render-text]="true" (after-load-complete)="onPdfLoadComplete($event)">
      </pdf-viewer>
    </div>

    <br />
    <br>
    <div *ngIf="loadQR">
      <h3 class="table-header">AFIP QR</h3>
      <form [formGroup]="formGroup">
        <mat-table #table [dataSource]="dataQR">
          <mat-header-row *matHeaderRowDef=" displayedColumnsQR"></mat-header-row>
          <ng-container matColumnDef="cuit">
            <mat-header-cell *matHeaderCellDef>CUIT</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="cuit" id="cuit_{{i}}" name="cuit_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <mat-header-cell *matHeaderCellDef>{{ t("fecha-emision") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="fecha" id="fecha_{{i}}" name="fecha_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="factura">
            <mat-header-cell *matHeaderCellDef>{{ t("tipo") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="factura" id="factura_{{i}}" name="factura_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="total">
            <mat-header-cell *matHeaderCellDef>{{ t("total") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="total" id="total_{{i}}" name="total_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="codCae">
            <mat-header-cell *matHeaderCellDef>CAE</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="codCae" id="codCae_{{i}}" name="codCae_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="numero">
            <mat-header-cell *matHeaderCellDef>{{ t("numero") }}</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <ng-container [formGroup]="row">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="numero" id="numero_{{i}}" name="numero_{{i}}">
                </mat-form-field>
              </ng-container>
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns:  displayedColumnsQR"></mat-row>
        </mat-table>
      </form>
      <br /><br />
    </div>
  </ng-container>
</div>