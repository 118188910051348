import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AyudaComponent } from './ayuda/ayuda.component';
import { LoginComponent } from './login/login.component';
import { PagoComponent } from './pago/pago.component';
import { CompraComponent } from './compra/compra.component';
import { PedidoComponent } from './pedido/pedido.component';
import { DashboardComponent } from './dashboard/dashboard.component';

export const userRoutes = [
  {
    path: 'pago',
    component: PagoComponent,
    label: 'Pago',
    icon: 'receipt_long',
  },
  {
    path: 'compra',
    component: CompraComponent,
    label: 'Compra',
    icon: 'receipt_long',
  } /*,
  {
    path: 'pedido',
    component: PedidoComponent,
    label: 'Pedido',
    icon: 'receipt_long'
  } */,
];

@NgModule({
  imports: [RouterModule.forRoot(userRoutes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(
  userRoutes,
  {
    preloadingStrategy: PreloadAllModules, // Comment to allow lazy loading
    onSameUrlNavigation: 'ignore',
    useHash: true, // Comment to avoid # prefix on the URL
  }
);
