import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { NanduComponent } from './nandu/nandu.component';

export const nanduRoutes = [
  {
    path: 'facturas',
    component: NanduComponent,
    label: 'Facturas',
    icon: 'receipt_long',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(nanduRoutes)],
  exports: [RouterModule],
})
export class NanduRoutingModule {}

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(
  nanduRoutes,
  {
    preloadingStrategy: PreloadAllModules, // Comment to allow lazy loading
    onSameUrlNavigation: 'ignore',
    useHash: true, // Comment to avoid # prefix on the URL
  }
);
