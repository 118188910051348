import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { CompraService } from './compra.service';
import { CompraComponent } from './compra.component';
import { ConfirmarComponent } from './confirmar/confirmar.component';
import { DesautComponent } from './confirmar/desautorizar.component';
import { RespuestaComponent } from './respuesta/respuesta.component';
import { PagoService } from '../pago/pago.service';
import {
  HistorialFoundPipe,
  PendientesFoundPipe,
} from '../shared/search-filter/search-filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
  ],
  declarations: [
    CompraComponent,
    ConfirmarComponent,
    DesautComponent,
    RespuestaComponent,
  ],
  exports: [MatTableModule, MatPaginatorModule],
  entryComponents: [ConfirmarComponent, DesautComponent, RespuestaComponent],
  providers: [CompraService, HistorialFoundPipe, PendientesFoundPipe],
})
export class CompraModule {}
