import { TranslocoService } from '@ngneat/transloco';
import { Component, ElementRef, OnInit } from '@angular/core';
import { DialogInitService } from '../services/dialog.service';
import { DashboardService } from './dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

const dialogWidth = '60vh'; // Ancho de los dialog
const dialogHeight = '40vh'; // Alto de los dialog

@Component({
  selector: 'ngx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public servicios: any;
  spinner: Boolean = false;
  visible;
  theme = localStorage.getItem('theme')
    ? localStorage.getItem('theme')
    : 'light-theme';

  constructor(
    public snackBar: MatSnackBar,
    public dashboardService: DashboardService,
    public translateService: TranslocoService,
    public router: Router
  ) {}

  ngOnInit() {
    localStorage.removeItem('route');
    this.formInit();
  }

  searchInput: string;
  onSearchInputValueEmitted(value: string) {
    this.searchInput = value;
  }

  formInit() {
    this.spinner = false;
    this.dashboardService.getServicioPorUsuario().subscribe((res) => {
      this.spinner = true;
      this.servicios = res.servicios;
    });
  }

  toAutorizaciones() {
    localStorage.setItem('route', 'Auth');
    this.router.navigate(['/pago']);
  }

  toNandu() {
    localStorage.setItem('route', 'Facturas');
    this.router.navigate(['/facturas']);
  }

  onclick() {
    this.visible = !this.visible;
  }
}
