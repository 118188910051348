import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable()
export class PagoService {
  public urlEstados = environment.baseUrl + 'consul/estados/';
  public urlPendientes = environment.baseUrl + 'autservicios/listas/op/reng/';
  public urlHistorial = environment.baseUrl + 'autservicios/listas/op/historial/';
  constructor(public http: HttpClient) { }

  public getOrdenesbyCompany(idcompany): Observable<any> {
    return this.http.get(
      this.urlPendientes + localStorage.getItem('iduserpcdr') + '/' + idcompany
    );
  }

  public getHistorial(idcompany): Observable<any> {
    return this.http.get(
      this.urlHistorial + localStorage.getItem('iduserpcdr') + '/' + idcompany
    );
  }

  public autorizarOrdenes(idcompany, orden): Observable<any> {
    return this.http.post(
      this.urlPendientes + localStorage.getItem('iduserpcdr') + '/' + idcompany,
      orden
    );
  }

  public desautorizarOrdenes(idcompany, orden): Observable<any> {
    return this.http.post(
      this.urlHistorial + localStorage.getItem('iduserpcdr') + '/' + idcompany,
      orden
    );
  }
}
