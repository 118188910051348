<body style="background-image: linear-gradient(to right bottom, #aaaaaa, #b7b7b7, #c4c4c4, #d2d2d2, #dfdfdf);">
    <div class="login-container">
        <mat-card fxLayout="column" fxLayoutAlign="space-between center">
            <div *ngIf="componentCssClass=='dark-theme'">
                <img src="../../assets/images/LogoDark-Enterprise.png" class="logo-condor">
            </div>
            <div *ngIf="componentCssClass!='dark-theme'">
                <img src="../../assets/images/LightEnterprise.png" class="logo-condor">
            </div>
            <form [formGroup]="validateForm" #formDirective="ngForm" style="margin-bottom: 15px; margin-top: 15px;">
                <mat-form-field class="login-inputs">
                    <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput autocomplete="off" placeholder="Usuario" formControlName="email" [maxLength]="40">
                    <mat-error *ngIf="validateForm.controls['email'].errors?.required">
                        Ingresa email
                    </mat-error>
                </mat-form-field>
                <br />
                <mat-form-field class="login-inputs">
                    <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">lock</mat-icon>
                    <input type="password" matInput placeholder="Nueva clave" formControlName="password"
                        [maxLength]="40" [type]="hide ? 'password' : 'text'">
                    <mat-icon matTooltip="{{ ( hide ? 'Mostrar' : 'Ocultar' ) }}" class="clickable" matSuffix
                        (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="validateForm.controls['password'].errors?.required">
                        Ingresa nueva clave
                    </mat-error>
                </mat-form-field>
                <br />
                <mat-form-field class="login-inputs">
                    <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">done</mat-icon>
                    <input matInput autocomplete="off" type="password" placeholder="Repetir clave"
                        formControlName="repetir" [maxLength]="40" [type]="hide_pass ? 'password' : 'text'">
                    <mat-icon matTooltip="{{ ( hide_pass ? 'Mostrar' : 'Ocultar' ) }}" class="clickable" matSuffix
                        (click)="hide_pass = !hide_pass">
                        {{hide_pass ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="validateForm.controls['repetir'].errors?.required">
                        Ingresa la nueva clave repetida
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <a mat-raised-button color="accent" fxLayout="column" type="submit" (click)="login()"
                        fxLayoutAlign="center center">Cancelar</a>
                    <a mat-raised-button color="primary" fxLayout="column" type="submit" (click)="resetPassword()"
                        fxLayoutAlign="center center" [disabled]="validateForm.invalid">Cambiar clave</a>
                </div>
            </form>
        </mat-card>
    </div>
</body>