import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SidenavService {
  public url = environment.baseUrl;
  public urlEmpresas = environment.baseUrl + 'emp/empresas/';
  iduserpcdr = localStorage.getItem('iduserpcdr');

  constructor(public http: HttpClient) {}
  public getCompanyPorUsuario(): Observable<any> {
    return this.http.get(this.urlEmpresas + localStorage.getItem('iduserpcdr'));
  }
}
