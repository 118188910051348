<div fxLayout="column" fxFill>
    <div mat-dialog-title>Concepto</div>
    <mat-form-field style="width: 100%;">
        <textarea matInput value="{{ concepto }}" cdkTextareaAutosize
        cdkAutosizeMinRows="4"
        cdkAutosizeMaxRows="20"></textarea>
    </mat-form-field>

    <div fxLayoutAlign="end center" class="buttons">
        <button mat-raised-button (click)="guardar()" color="primary">
            Guardar
        </button>
    </div>
</div>
