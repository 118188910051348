import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NanduService } from './nandu.service';
import { NanduComponent } from './nandu.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConceptoComponent } from './concepto/concepto';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
    PdfViewerModule,
  ],
  declarations: [NanduComponent, ConceptoComponent],
  exports: [MatTableModule, MatPaginatorModule],
  entryComponents: [ConceptoComponent],
  providers: [NanduService],
})
export class NanduModule {}
