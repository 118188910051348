import { HistorialFoundPipe, PendientesFoundPipe } from './../shared/search-filter/search-filter.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { PagoService } from './pago.service';
import { PagoComponent } from './pago.component';
import { ConfirmarComponent } from './confirmar/confirmar.component';
import { DesautComponent } from './confirmar/desautorizar.component';
import { RespuestaComponent } from './respuesta/respuesta.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    SharedModule
  ],
  declarations: [
    PagoComponent,
    ConfirmarComponent,
    DesautComponent,
    RespuestaComponent,
  ],
  exports: [MatTableModule, MatPaginatorModule],
  entryComponents: [ConfirmarComponent, DesautComponent, RespuestaComponent],
  providers: [PagoService, HistorialFoundPipe, PendientesFoundPipe],
})
export class PagoModule {}
