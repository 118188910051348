import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmar',
  templateUrl: './desautorizar.component.html',
  styleUrls: ['../compra.component.scss'],
})
export class DesautComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DesautComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  accept() {
    this.dialogRef.close({ data: true });
  }

  cancel(): void {
    this.dialogRef.close({ data: false });
  }
}
