import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable()
export class PedidoService {
    public urlEstados = environment.baseUrl + "consul/estados/";
    iduserpcdr = localStorage.getItem('iduserpcdr');
    constructor(public http: HttpClient) { }

}