import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class DashboardService {
  public url = environment.baseUrl;
  public urlEmpleados = environment.baseUrl + 'autservicios/servicios/';
  constructor(public http: HttpClient) {}

  public getServicioPorUsuario(): Observable<any> {
    return this.http.get(
      this.urlEmpleados + localStorage.getItem('iduserpcdr')
    );
  }
}
