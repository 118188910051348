import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable()
export class CompraService {
  public urlEstados = environment.baseUrl + 'consul/estados/';
  public urlPendientes = environment.baseUrl + 'autservicios/listas/op/reng/';
  public urlHistorial =
    environment.baseUrl + 'autservicios/listas/op/historial/';
  constructor(public http: HttpClient) {}

  public url = environment.baseUrl + 'cdr_lista/';
  public getCompanyPorUsuario(): Observable<any> {
    return this.http.get(
      this.url +
        'listar/' +
        localStorage.getItem('iduserpcdr') +
        '?objeto=empresas_usuario/' +
        localStorage.getItem('iduserpcdr')
    );
  }

  public getOrdenesbyCompany(idcompany): Observable<any> {
    return this.http.get(
      this.url +
        'listar/' +
        localStorage.getItem('iduserpcdr') +
        '?objeto=ordenescompra/' +
        localStorage.getItem('iduserpcdr') +
        '/' +
        idcompany
    );
  }

  public getHistorial(idcompany): Observable<any> {
    return this.http.get(
      this.url +
        'listar/' +
        localStorage.getItem('iduserpcdr') +
        '?objeto=ordenescompraarev/' +
        localStorage.getItem('iduserpcdr') +
        '/' +
        idcompany
    );
  }

  public autorizarOrdenes(idcompany, orden): Observable<any> {
    return this.http.post(
      this.url +
        'insertar/' +
        localStorage.getItem('iduserpcdr') +
        '?objeto=ordenescompraaut/' +
        localStorage.getItem('iduserpcdr') +
        '/' +
        idcompany,
      orden
    );
  }

  public desautorizarOrdenes(idcompany, orden): Observable<any> {
    return this.http.post(
      this.url +
        'insertar/' +
        localStorage.getItem('iduserpcdr') +
        '?objeto=ordenescomprarev/' +
        localStorage.getItem('iduserpcdr') +
        '/' +
        idcompany,
      orden
    );
  }
}
