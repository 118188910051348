import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { PedidoService } from './pedido.service';
import { PedidoComponent } from './pedido.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule
  ],
  declarations: [
    PedidoComponent,
  ],  
  exports: [
    MatTableModule,
    MatPaginatorModule
  ],
  entryComponents:[
  ],
  providers: [
    PedidoService
  ]
})

export class PedidoModule { }
