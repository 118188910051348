<div class="center-table">
  <ng-container *transloco="let t; read: 'ordenes-pago'">
    <!---------------------- TABLAS PARA WEB ---------------------->
    <div fxShow fxHide.lt-md>
      <h2 fxLayoutAlign="center center">{{ t("titulo") }}</h2>
      <!-- SELECT EMPRESAS -->
      <div fxLayoutAlign="start">
        <form [formGroup]="formGroup" id="ngForm">
          <mat-form-field appearance="legacy" class="select-nav">
            <mat-label>{{ t("empresas") }}</mat-label>
            <mat-select formControlName="empresa">
              <mat-option *ngFor="let item of empresasList" [value]="item.emp" (click)="getOrdenesbyCompany()">
                {{ item.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <!-- TAB PENDIENTES -->
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{ t('pendientes') }}">
          <!-- FILTROS -->
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <!-- BOTON AUTORIZAR -->
            <div>
              <button class="mat-button-wrapper" type="button" (click)="openAutorizar()" mat-raised-button
                color="primary" *ngIf="selection.hasValue()">
                {{ t("autorizar") }}
              </button>
            </div>

            <!-- REFRESH, EXPORT y SEARCH -->
            <div fxLayoutAlign="end center">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <!-- SEARCH TABLE -->
                <mat-form-field style="width: 300px">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-icon> search </mat-icon>
                    <input matInput (keyup)="applyFilterPend($event.target)" autocomplete="off"
                      [(ngModel)]="filterValuePen" [ngModelOptions]="{ standalone: true }" />
                  </div>
                </mat-form-field>
                <!-- export -->
                <button mat-icon-button [matMenuTriggerFor]="menuPendientes" style="margin-left: 4%;">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menuPendientes="matMenu">
                  <button mat-menu-item (click)="downloadExcelPendientes()">
                    <mat-icon>save_alt</mat-icon>
                    <span>{{ t("exportar") }}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>

          <!-- TABLA -->
          <div fxLayout="row" fxLayoutAlign="space-around center" class="spinner" *ngIf="!spinner">
            <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
          </div>

          <div *ngIf="spinner">
            <mat-table #table id="dataSourceGrid" [dataSource]="dataSourcePendientes" matSort #sortPendientes="matSort"
              style="margin-bottom: 20px">
              <!-- fecha -->
              <ng-container matColumnDef="fecpago">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                  t("fecha")
                  }}</mat-header-cell>
                <mat-cell *matCellDef="let item"> {{ item.fecpago }} </mat-cell>
              </ng-container>

              <!-- orden de pago -->
              <ng-container matColumnDef="ordpago">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("orden") }}
                </mat-header-cell>
                <mat-cell *matCellDef="let item"> {{ item.ordpago }} </mat-cell>
              </ng-container>

              <!-- firma -->
              <ng-container matColumnDef="firma">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("firma") }}
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                  <div *ngIf="item.firma == '1'">
                    {{ t("1") }}
                  </div>
                  <div *ngIf="item.firma == '2'">
                    {{ t("2") }}
                  </div>
                  <div *ngIf="item.firma == '3'">
                    {{ t("3") }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- beneficiario -->
              <ng-container matColumnDef="beneficiario" style>
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                  t("beneficiario")
                  }}</mat-header-cell>
                <mat-cell *matCellDef="let item">
                  <div class="cut-text">{{ item.beneficiario }}</div>
                </mat-cell>
              </ng-container>

              <!-- importe -->
              <ng-container matColumnDef="importe">
                <mat-header-cell *matHeaderCellDef class="header-importe" mat-sort-header arrowPosition="before">{{
                  t("importe") }}</mat-header-cell>
                <mat-cell *matCellDef="let item">
                  <div *ngIf="item.descum == 'Pesos'">$ {{ item.importe }}</div>
                  <div *ngIf="item.descum == 'Dolares'">
                    U$S {{ item.importe }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- autorizar -->
              <ng-container matColumnDef="select">
                <mat-header-cell class="mat-table-buttons" mat-header-cell *matHeaderCellDef>
                  <mat-checkbox #ref (change)="
                    $event
                      ? masterToggle(ref, selection, dataSourcePendientes)
                      : null
                  " [checked]="
                    selection.hasValue() &&
                    isAllSelected(dataSourcePendientes, selection) 
                  " [indeterminate]="
                    selection.hasValue() &&
                    !isAllSelected(dataSourcePendientes, selection)
                  " color="primary">
                  </mat-checkbox>
                </mat-header-cell>
                <mat-cell class="mat-table-buttons" id="delete-checkbox" mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" color="primary">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="columnsPendientes"></mat-header-row>
              <mat-row *matRowDef="let row; columns: columnsPendientes"></mat-row>
            </mat-table>
          </div>
          <!-- Paginador -->
          <mat-paginator class="fh-color" #paginatorPendientes [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 75, 100]"
            [showFirstLastButtons]="true">
          </mat-paginator>
          <br />
        </mat-tab>

        <!-- TAB HISTORIAL -->
        <mat-tab label="{{ t('historial') }}">
          <!-- FILTROS -->
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <!-- BOTON AUTORIZAR -->
            <div>
              <button class="mat-button-wrapper" type="button" (click)="openDesautorizar()" mat-raised-button
                color="primary" *ngIf="selection.hasValue()">
                {{ t("desautorizar") }}
              </button>
            </div>

            <!-- REFRESH, EXPORT y SEARCH -->
            <div fxLayoutAlign="end center">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <!-- SEARCH TABLE -->
                <mat-form-field style="width: 300px">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-icon> search </mat-icon>
                    <input matInput (keyup)="applyFilterHist($event.target)" autocomplete="off"
                      [(ngModel)]="filterValuePen" [ngModelOptions]="{ standalone: true }" />
                  </div>
                </mat-form-field>
                <!-- export -->
                <button mat-icon-button [matMenuTriggerFor]="menuHistorial" style="margin-left: 4%;">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menuHistorial="matMenu">
                  <button mat-menu-item (click)="downloadExcelHistorial()">
                    <mat-icon>save_alt</mat-icon>
                    <span>{{ t("exportar") }}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
          <!-- TABLA -->
          <div fxLayout="row" fxLayoutAlign="space-around center" class="spinner" *ngIf="!spinner">
            <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
          </div>
          <div *ngIf="spinner">
            <mat-table #table id="dataSourceGrid" [dataSource]="dataSourceHistorial" matSort #sortHistorial="matSort"
              style="margin-bottom: 20px">
              <!-- orden de pago -->
              <ng-container matColumnDef="ordpago">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("orden") }}
                </mat-header-cell>
                <mat-cell *matCellDef="let item"> {{ item.ordpago }} </mat-cell>
              </ng-container>

              <!-- firma -->
              <ng-container matColumnDef="firma">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("firma") }}
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                  <div *ngIf="item.firma == '1'">
                    {{ t("1") }}
                  </div>
                  <div *ngIf="item.firma == '2'">
                    {{ t("2") }}
                  </div>
                  <div *ngIf="item.firma == '3'">
                    {{ t("3") }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- fecha -->
              <ng-container matColumnDef="fecaut">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                  t("fecha")
                  }}</mat-header-cell>
                <mat-cell *matCellDef="let item"> {{ item.fecaut }} </mat-cell>
              </ng-container>

              <!-- beneficiario -->
              <ng-container matColumnDef="beneficiario">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                  t("beneficiario")
                  }}</mat-header-cell>
                <mat-cell *matCellDef="let item">
                  {{ item.beneficiario }}
                </mat-cell>
              </ng-container>

              <!-- aprobado -->
              <ng-container matColumnDef="aprobado">
                <mat-header-cell *matHeaderCellDef class="header-aprobado" mat-sort-header arrowPosition="before">{{
                  t("importe") }}</mat-header-cell>
                <mat-cell *matCellDef="let item" class="mat-column-aprobado">
                  <div *ngIf="item.descum == 'Pesos'">$ {{ item.aprobado }}</div>
                  <div *ngIf="item.descum == 'Dolares'">
                    U$S {{ item.aprobado }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- desautorizar -->
              <ng-container matColumnDef="select">
                <mat-header-cell class="mat-table-buttons" mat-header-cell *matHeaderCellDef>
                  <div *ngIf="revierteOk == false">
                    <mat-checkbox disabled="true" matTooltip="No hay ordenes disponibles para seleccionar"></mat-checkbox>
                  </div>
                  <div *ngIf="revierteOk == true">
                    <mat-checkbox #ref (change)="
                      $event
                        ? masterToggle(ref, selection, dataSourceHistorial)
                        : null
                    " [checked]="
                      selection.hasValue() &&
                      isAllSelected(dataSourceHistorial, selection)
                    " [indeterminate]="
                      selection.hasValue() &&
                      !isAllSelected(dataSourceHistorial, selection)
                    " color="primary">
                    </mat-checkbox>
                  </div>
                </mat-header-cell>
                <mat-cell class="mat-table-buttons" id="delete-checkbox" mat-cell *matCellDef="let row">
                  <div *ngIf="row.revierteok == 'S'">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)" color="primary">
                    </mat-checkbox>
                  </div>
                  <div *ngIf="row.revierteok == 'N'">
                    <mat-checkbox disabled="true" matTooltip="No es posible seleccionar"></mat-checkbox>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="columnsHistorial"></mat-header-row>
              <mat-row *matRowDef="let row; columns: columnsHistorial"></mat-row>
            </mat-table>
            <br />
          </div>
          <!-- Paginador -->
          <mat-paginator class="fh-color" #paginatorHistorial [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 75, 100]"
            [showFirstLastButtons]="true">
          </mat-paginator>
          <br />
        </mat-tab>
      </mat-tab-group>
    </div>

    <!---------------------- CARDS PARA MOBILE ---------------------->
    <div fxHide fxShow.lt-md>
      <div fxLayoutAlign="center center" class="title">{{ t("titulo") }}</div>

      <!-- SELECT EMPRESAS -->
      <div fxLayoutAlign="start">
        <form [formGroup]="formGroup" id="ngForm">
          <mat-form-field appearance="legacy">
            <mat-label>{{ t("empresas") }}</mat-label>
            <mat-select formControlName="empresa">
              <mat-option *ngFor="let item of empresasList" [value]="item.emp" (click)="getOrdenesbyCompany()">
                {{ item.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)">
        <!-- TAB PENDIENTES -->
        <mat-tab label="{{ t('pendientes') }}">
          <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <!-- FILTROS -->
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <!-- SEARCH CARDS -->
              <div fxLayoutAlign="start right" class="search-mobile">
                <search-filter-component (inputValue)="onSearchPendientes($event)">
                </search-filter-component>
              </div>
              <!-- REFRESH Y EXPORT -->
              <div fxLayoutAlign="end right">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <!-- export -->
                  <button mat-icon-button [matMenuTriggerFor]="menuPendientes">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menuPendientes="matMenu">
                    <button mat-menu-item (click)="downloadExcelPendientes()">
                      <mat-icon>save_alt</mat-icon>
                      <span>{{ t("exportar") }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="check-mobile">
              <!-- CHECK ALL SELECTED -->
              <div *ngIf="sizeOrdenes > 0">
                <mat-checkbox (change)="allSelectCardPend($event)" [checked]="checkPendientes">
                  {{ t("marcar-todo") }}
                </mat-checkbox>
              </div>
              <!-- BOTON AUTORIZAR -->
              <div *ngIf="btnAutorizar == true">
                <button class="mat-button-wrapper" type="button" (click)="openAutorizar()" mat-raised-button
                  color="primary" style="font-size: 13px !important">
                  {{ t("autorizar") }}
                </button>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-around center" class="spinner" *ngIf="!spinner">
            <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
          </div>

          <div *ngIf="spinner">
            <div *ngFor="let item of ordenes; let i = index" style="margin-bottom: 15px">
              <ng-container *ngIf="item | pendientesFoundPipe : inputPendientes">
                <mat-card (click)="onSelectCardPend(i, item)"
                  [ngClass]="{ 'card-selected mat-elevation-z4': tapAutorizar[i]}">
                  <span class="selection">
                    <mat-icon color="primary" style="font-size: 24px">check_circle</mat-icon>
                  </span>

                  <div fxLayout="row">
                    <div fxLayout="column" fxLayoutAlign="space-between start">
                      <!-- BENEFICIARIO -->
                      <div fxLayout="column" class="margin-cards">
                        <div class="bold">{{ t("beneficiario") }}</div>
                        {{ item.beneficiario }}
                      </div>

                      <!-- ORDEN + FECHA -->
                      <div fxLayout="row">
                        <div fxLayout="column" class="margin-cards">
                          <div class="bold">{{ t("orden") }}</div>
                          {{ item.ordpago }}
                        </div>

                        <div fxLayout="column" class="margin-cards">
                          <div class="bold">{{ t("fecha-pago") }}</div>
                          {{ item.fecpago }}
                        </div>
                      </div>

                      <!-- IMPORTE + FIRMA -->
                      <div fxLayout="row">
                        <div fxLayout="column" class="margin-cards">
                          <div class="bold">{{ t("importe") }}</div>
                          <div *ngIf="item.descum == 'Pesos'">
                            $ {{ item.importe }}
                          </div>
                          <div *ngIf="item.descum == 'U$S Div Vend'">
                            U$S {{ item.importe }}
                          </div>
                        </div>

                        <div fxLayout="column" class="margin-cards">
                          <div class="bold">{{ t("firma") }}</div>
                          <div *ngIf="item.firma == '1'">
                            {{ t("1") }}
                          </div>
                          <div *ngIf="item.firma == '2'">
                            {{ t("2") }}
                          </div>
                          <div *ngIf="item.firma == '3'">
                            {{ t("3") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </ng-container>
            </div>
          </div>
          <scroll-top></scroll-top>
        </mat-tab>

        <!-- TAB HISTORIAL -->
        <mat-tab label="{{ t('historial') }}">
          <!-- FILTROS -->
          <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <!-- SEARCH CARDS -->
              <div fxLayoutAlign="start right" class="search-mobile">
                <search-filter-component (inputValue)="onSearchHistorial($event)">
                </search-filter-component>
              </div>
              <!-- REFRESH Y EXPORT -->
              <div fxLayoutAlign="end right">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <!-- export -->
                  <button mat-icon-button [matMenuTriggerFor]="menuHistorial">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menuHistorial="matMenu">
                    <button mat-menu-item (click)="downloadExcelHistorial()">
                      <mat-icon>save_alt</mat-icon>
                      <span>{{ t("exportar") }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="check-mobile">
              <!-- CHECK ALL SELECTED -->
              <div *ngIf="sizeHistorial > 0">
                <mat-checkbox (change)="allSelectCardHis($event)" [checked]="checkHistorial"
                  [disabled]="disabledCheck==true">
                  {{ t("marcar-todo") }}
                </mat-checkbox>
              </div>
              <!-- BOTON DESAUTORIZAR -->
              <div *ngIf="btnDesautorizar == true">
                <button class="mat-button-wrapper" type="button" (click)="openDesautorizar()" mat-raised-button
                  color="primary" style="font-size: 13px !important">
                  {{ t("desautorizar") }}
                </button>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-around center" class="spinner" *ngIf="!spinner">
            <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
          </div>

          <div *ngIf="spinner">
            <div *ngFor="let item of historial; let i = index" style="margin-bottom: 15px">
              <ng-container *ngIf="item | historialFoundPipe : inputHistorial">
                <mat-card (click)="onSelectCardHist(i, item)"
                  [ngClass]="{ 'card-selected mat-elevation-z4': tapDesautorizar[i], 'disabled' : disabledCard[i]}">
                  <span class="selection">
                    <mat-icon color="primary" style="font-size: 24px">check_circle</mat-icon>
                  </span>
                  <div fxLayout="row">

                    <div fxLayout="column" fxLayoutAlign="space-between start">
                      <!-- BENEFICIARIO -->
                      <div fxLayout="column" class="margin-cards">
                        <div class="bold">{{ t("beneficiario") }}</div>
                        {{ item.beneficiario }}
                      </div>

                      <!-- ORDEN + FECHA -->
                      <div fxLayout="row">
                        <div fxLayout="column" class="margin-cards">
                          <div class="bold">{{ t("orden") }}</div>
                          {{ item.ordpago }}
                        </div>

                        <div fxLayout="column" class="margin-cards">
                          <div class="bold">{{ t("fecha-pago") }}</div>
                          {{ item.fecaut }}
                        </div>
                      </div>

                      <!-- IMPORTE + FIRMA -->
                      <div fxLayout="row">
                        <div fxLayout="column" class="margin-cards">
                          <div class="bold">{{ t("importe") }}</div>
                          <div *ngIf="item.descum == 'Pesos'">
                            $ {{ item.aprobado }}
                          </div>
                          <div *ngIf="item.descum == 'U$S Div Vend'">
                            U$S {{ item.aprobado }}
                          </div>
                        </div>

                        <div fxLayout="column" class="margin-cards">
                          <div class="bold">{{ t("firma") }}</div>
                          <div *ngIf="item.firma == '1'">
                            {{ t("1") }}
                          </div>
                          <div *ngIf="item.firma == '2'">
                            {{ t("2") }}
                          </div>
                          <div *ngIf="item.firma == '3'">
                            {{ t("3") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </ng-container>
            </div>
          </div>
          <scroll-top></scroll-top>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</div>