import { TranslocoModule } from '@ngneat/transloco';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { RafikiNoDataComponent } from './rafiki-illustrations/rafiki-illustrations.component';
import { SearchFilterComponent, SharedSearchFilterService } from './search-filter/search-filter.component';
import { DateAdapter, MAT_DATE_FORMATS, } from '@angular/material/core';
import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormsModule } from '@angular/forms';
import localeEsAr from '@angular/common/locales/es-AR';
import { from } from 'rxjs';

import {
    NotificationsAdminFoundPipe,
    NewsFoundPipe,
    NewsAdminFoundPipe,
    PendientesFoundPipe,
    HistorialFoundPipe
} from './search-filter/search-filter.pipe';
import { NoDataComponent } from './no-data/no-data.component';
import { ExcelService } from './services/xlsx.service';
import { MatPaginationIntlService } from './services/mat-paginator-translate';
import { ScrollTopComponent } from './scroll-top/scroll-top';

@NgModule({

    imports: [
        CommonModule,
        RouterModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        MatBadgeModule,
        MatFormFieldModule,
        MatTreeModule,
        FormsModule,
        FlexLayoutModule,
        TranslocoModule
    ],
    exports: [
        A11yModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        NoDataComponent,
        FlexLayoutModule,
        RafikiNoDataComponent,
        SearchFilterComponent,
        ScrollTopComponent,
        // Search Filter pipes
        NotificationsAdminFoundPipe,
        NewsFoundPipe,
        NewsAdminFoundPipe,
        PendientesFoundPipe,
        HistorialFoundPipe
    ],
    declarations: [
        RafikiNoDataComponent,
        SearchFilterComponent,
        ScrollTopComponent,
        // Search Filter pipes
        NotificationsAdminFoundPipe,
        NewsFoundPipe,
        NewsAdminFoundPipe,
        NoDataComponent,
        PendientesFoundPipe,
        HistorialFoundPipe
    ],
    entryComponents: [
    ],
    providers: [
        NoDataComponent,
        RafikiNoDataComponent,
        SearchFilterComponent,
        ScrollTopComponent,
        ExcelService,
        SharedSearchFilterService,
        { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MatPaginatorIntl,
          useClass: MatPaginationIntlService
        }
    ]
})

export class SharedModule {

    constructor(
        public matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer) {
        this.matIconRegistry.addSvgIcon(
            "key-enter",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/key-enter.svg")
        );
    }

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [MatIconRegistry]
        }
    }
}
