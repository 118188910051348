<div class="center-20">
  <div fxLayoutAlign="center center" class="margin-title">
    <div *ngIf="theme == 'dark-theme'">
      <img
        src="../../assets/images/LogoDark-Enterprise.png"
        class="logo-condor"
      />
    </div>
    <div *ngIf="theme != 'dark-theme'">
      <img src="../../assets/images/LightEnterprise.png" class="logo-condor" />
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="space-around center"
    class="spinner"
    *ngIf="!spinner"
  >
    <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
  </div>
  <div *ngIf="spinner">
    <!-- accesos mobile -->
    <div fxHide fxShow.lt-md>
      <ng-container *transloco="let t; read: 'dashboard'">
        <div style="margin-bottom: 15px" fxLayout="row" fxLayoutAlign="center">
          <div
            (click)="toAutorizaciones()"
            class="mobile-aling"
            style="margin: 15px"
          >
            <mat-card class="pointer" style="width: 200px">
              <div *ngIf="theme === 'dark-theme'">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                  <div class="display-img-D">
                    <img src="../../assets/images/fill-out-bro.svg" />
                  </div>
                  <div fxFlex="100%" class="title-card-dark">
                    <mat-card-title class="title">{{
                      t("AUT")
                    }}</mat-card-title>
                  </div>
                </div>
              </div>
              <div *ngIf="theme === 'light-theme'">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                  <div class="display-img-L">
                    <img src="../../assets/images/fill-out-bro.svg" />
                  </div>
                  <div fxFlex="100%" class="title-card-light">
                    <mat-card-title class="title">{{
                      t("AUT")
                    }}</mat-card-title>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div (click)="toNandu()" class="mobile-aling" style="margin: 15px">
            <mat-card class="pointer" style="width: 200px">
              <div *ngIf="theme === 'dark-theme'">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                  <div class="display-img-D">
                    <img src="../../assets/images/documents-bro.svg" />
                  </div>
                  <div fxFlex="100%" class="title-card-dark">
                    <mat-card-title class="title">
                      {{ t("facturas") }}
                    </mat-card-title>
                  </div>
                </div>
              </div>
              <div *ngIf="theme === 'light-theme'">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                  <div class="display-img-L">
                    <img src="../../assets/images/documents-bro.svg" />
                  </div>
                  <div fxFlex="100%" class="title-card-light">
                    <mat-card-title class="title">
                      {{ t("facturas") }}
                    </mat-card-title>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <button (click)="onclick()" type="button" style="opacity: 0">
          ............................................
        </button>
      </ng-container>
    </div>

    <!--  accesos web -->
    <div fxShow fxHide.lt-md>
      <ng-container *transloco="let t; read: 'dashboard'">
        <div fxLayout="row" fxLayoutAlign="center">
          <div
            (click)="toAutorizaciones()"
            style="margin: 15px"
            *ngIf="!item.leido"
          >
            <mat-card class="pointer" style="width: 200px">
              <div *ngIf="theme === 'dark-theme'">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="display-img-D">
                    <img src="../../assets/images/fill-out-bro.svg" />
                  </div>
                  <div fxFlex="100%" class="title-card-dark">
                    <mat-card-title class="title">{{
                      t("AUT")
                    }}</mat-card-title>
                  </div>
                  <div class="display-img-D">
                    <img src="../../assets/images/documents-bro.svg" />
                  </div>
                  <div fxFlex="100%" class="title-card-dark">
                    <mat-card-title class="title">
                      {{ t("facturas") }}
                    </mat-card-title>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div (click)="toNandu()" style="margin: 15px">
            <mat-card class="pointer" style="width: 200px">
              <div *ngIf="theme === 'light-theme'">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="display-img-L">
                    <img src="../../assets/images/fill-out-bro.svg" />
                  </div>
                  <div fxFlex="100%" class="title-card-light">
                    <mat-card-title class="title">{{
                      t("AUT")
                    }}</mat-card-title>
                  </div>
                  <div class="display-img-L">
                    <img src="../../assets/images/documents-bro.svg" />
                  </div>
                  <div fxFlex="100%" class="title-card-light">
                    <mat-card-title class="title">
                      {{ t("facturas") }}</mat-card-title
                    >
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <button (click)="onclick()" type="button" style="opacity: 0">
          ............................................
        </button>
      </ng-container>
    </div>
  </div>
</div>
