import { FormsModule } from '@angular/forms';
import { ChangeTheme } from './services/changeTheme.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UserRoutingModule } from './user-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { DialogInitService } from './services/dialog.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginModule } from './login/login.module';
import { AvatarModule } from 'ngx-avatar';
import { AyudaModule } from './ayuda/ayuda.modules';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NotificacionesDialogComponent } from './sidenav/notificaciones/notificaciones.component';
import { TranslocoRootModule } from './transloco-root.module';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { MatRadioModule } from '@angular/material/radio';
import { JwtInterceptor } from './login/jwt.interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CompraModule } from './compra/compra.modules';
import { PagoModule } from './pago/pago.modules';
import { PedidoModule } from './pedido/pedido.modules';
import { RoutingModule } from './routing.module';
import { NanduRoutingModule } from './nandu-routing.module';
import { NanduModule } from './nandu/nandu.modules';

const apiKey = 'AIzaSyBv13lAVkrfv-NWjkLrR1RKu01JtQooU8w';
@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    NotificacionesDialogComponent,
    ConfiguracionesComponent,
  ],
  imports: [
    AyudaModule,
    AvatarModule,
    UserRoutingModule,
    NanduRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    DashboardModule,
    HttpClientModule,
    LoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    PdfViewerModule,
    TranslocoRootModule,
    GoogleMapsModule,
    AgmCoreModule.forRoot({ apiKey, libraries: ['places'] }),
    CompraModule,
    PagoModule,
    PedidoModule,
    MatGoogleMapsAutocompleteModule,
    MatMomentDateModule,
    MatRadioModule,
    NanduModule,
    RoutingModule,
    FormsModule,
  ],
  providers: [
    DialogInitService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ChangeTheme,
  ],
  bootstrap: [AppComponent, SidenavComponent],
  entryComponents: [SidenavComponent, NotificacionesDialogComponent],
})
export class AppModule {}
