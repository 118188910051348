import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@ngneat/transloco';
import { DialogInitService } from '../services/dialog.service';
import {
  HistorialFoundPipe,
  PendientesFoundPipe,
} from '../shared/search-filter/search-filter.pipe';
import { ExcelService } from '../shared/services/xlsx.service';
import { SidenavService } from '../sidenav/sidenav.service';
import { ConfirmarComponent } from './confirmar/confirmar.component';
import { DesautComponent } from './confirmar/desautorizar.component';
import { PagoService } from './pago.service';
import { RespuestaComponent } from './respuesta/respuesta.component';

interface Empresa {
  emp: number;
  nombre: string;
}

let dialogWidth;
let dialogHeight;
if (screen.width < 800) {
  dialogWidth = '100vw';
  dialogHeight = '100vh';
} else {
  dialogWidth = '40vh';
  dialogHeight = '40vh';
}
@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss'],
})
export class PagoComponent implements OnInit {
  // Filas a visualizar en la tabla
  columnsPendientes = [
    'select',
    'fecpago',
    'ordpago',
    'beneficiario',
    'importe',
    'firma',
  ];
  columnsHistorial = [
    'select',
    'fecaut',
    'ordpago',
    'beneficiario',
    'aprobado',
    'firma',
  ];
  formGroup = new FormGroup({});
  dataSourcePendientes: any;
  dataSourceHistorial: any;
  selection = new SelectionModel<any>(true, []);
  ordenes;
  empresasList: Empresa[] = [];
  filteredValuePen: string = '';
  filterValuePen: string = '';
  filteredValueHist: string = '';
  filterValueHist: string = '';
  tableToXLSX_Pendientes: any[] = [];
  tableToXLSX_Historial: any[] = [];
  selectedCompany;
  traducciones: string[];
  historial: any;
  sizeOrdenes: any;
  sizeHistorial: any;
  tapAutorizar = [false];
  tapDesautorizar = [false];
  disabledCard = [false];
  btnAutorizar: boolean;
  btnDesautorizar: boolean;
  pickedAutorizar = [];
  pickedDesautorizar = [];
  checkHistorial: boolean;
  checkPendientes: boolean;
  autorizarResponse = [];
  desautorizarResponse = [];
  disabledCheck: boolean = false;
  spinner: Boolean = true;
  revierteOk: Boolean = true;
  //Paginadores de historial y pendientes
  @ViewChild('paginatorPendientes') paginatorPendientes: MatPaginator;
  @ViewChild('paginatorHistorial') paginatorHistorial: MatPaginator;
  // Sort Pendientes
  @ViewChild('sortPendientes') sortPendientes = new MatSort();
  // Sort Historial
  @ViewChild('sortHistorial') sortHistorial = new MatSort();

  constructor(
    public pagoService: PagoService,
    public dialogInitService: DialogInitService,
    public sidenavService: SidenavService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public translateService: TranslocoService,
    public xlsxService: ExcelService,
    public dialog: MatDialog,
    public pipeHistorial: HistorialFoundPipe,
    public pipePendientes: PendientesFoundPipe
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      empresa: [null],
    });
    this.formInit();
    this.generarTraducciones();
  }

  formInit() {
    this.sidenavService.getCompanyPorUsuario().subscribe((res) => {
      this.empresasList = res.empresas;
      if (this.empresasList.length == 1) {
        this.formGroup.controls['empresa'].setValue(this.empresasList[0].emp);
        this.getOrdenesbyCompany();
      }
    });
  }

  getOrdenesbyCompany() {
    this.spinner = false;
    if (this.formGroup.value.empresa) {
      this.selectedCompany = this.formGroup.value.empresa;
      this.pagoService
        .getOrdenesbyCompany(this.formGroup.value.empresa)
        .subscribe((res) => {
          this.ordenes = res.opago;
          this.sizeOrdenes = this.ordenes.length;
          this.tableToXLSX_Pendientes = this.ordenes;
          this.spinner = true;

          if (this.ordenes) {
            this.dataSourcePendientes = new MatTableDataSource<any>(
              this.ordenes
            );
            this.dataSourcePendientes.paginator = this.paginatorPendientes;
            this.dataSourcePendientes.sort = this.sortPendientes;
          }
        });

      this.pagoService
        .getHistorial(this.formGroup.value.empresa)
        .subscribe((res) => {
          this.historial = res.opago;
          this.sizeHistorial = this.historial.length;
          this.tableToXLSX_Historial = this.historial;

          //CARGA DE ORDENES HISTORIAL
          if (this.historial) {
            this.dataSourceHistorial = new MatTableDataSource<any>(
              this.historial
            );
            this.dataSourceHistorial.paginator = this.paginatorHistorial;
            this.dataSourceHistorial.sort = this.sortHistorial;
            let disabled = 0;
            for (let i = 0; i < this.historial.length; i++) {
              if (this.historial[i].revierteok == 'N') {
                this.disabledCard[i] = true;
                disabled++;
              }
            }
            if (disabled == this.historial.length) {
              this.disabledCheck = true;
            }
          }
        });
    } else {
      this.snackBar.open(this.traducciones['errores'].empresa, 'X', {
        duration: 4000,
      });
    }
  }

  /* <<<<AUTORIZAR>>>> */

  openAutorizar(): void {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data == true) {
        if (screen.width < 800) {
          this.autorizarMobile();
        } else {
          this.Autorizar(this.selection);
        }
      }
    });
  }

  Autorizar(selectionModel: SelectionModel<any>) {
    this.autorizarResponse = [];
    for (let index = 0; index < selectionModel.selected.length; index++) {
      this.pagoService
        .autorizarOrdenes(this.selectedCompany, selectionModel.selected[index])
        .subscribe(
          (res) => {
            this.autorizarResponse[index] = res.errortxt;
            if (index == selectionModel.selected.length - 1) {
              this.getOrdenesbyCompany();
              selectionModel.clear();
              this.responseModal(this.autorizarResponse);
            }
          },
          (err) => {
            this.autorizarResponse[index] = err.errortxt;
            if (index == selectionModel.selected.length - 1) {
              this.responseModal(this.autorizarResponse);
            }
          }
        );
    }
  }

  autorizarMobile() {
    this.autorizarResponse = [];
    for (let index = 0; index < this.pickedAutorizar.length; index++) {
      this.pagoService
        .autorizarOrdenes(this.selectedCompany, this.pickedAutorizar[index])
        .subscribe(
          (res) => {
            this.autorizarResponse[index] = res.errortxt;
            if (index == this.pickedAutorizar.length - 1) {
              this.getOrdenesbyCompany();
              this.responseModal(this.autorizarResponse);
            }
          },
          (err) => {
            this.autorizarResponse[index] = err.errortxt;
            if (index == this.pickedAutorizar.length - 1) {
              this.responseModal(this.autorizarResponse);
            }
          }
        );
    }
    this.clearTab('Pendientes');
  }

  onSelectCardPend(index, item: any) {
    this.tapAutorizar[index] = !this.tapAutorizar[index];
    const tab = 'Pendientes';
    this.checkPendientes = false;
    let selection = true;

    if (this.tapAutorizar[index] == true) {
      this.pickedAutorizar.push(item);
    } else {
      this.pickedAutorizar.splice(
        this.arrayPendientes(this.pickedAutorizar, item),
        1
      );
    }

    if (this.pickedAutorizar.length == 0) {
      selection = false;
    }

    this.resetTab(this.checkPendientes, selection, tab);
  }

  //elimina ordenes deseleccionadas
  arrayPendientes(pickedAutorizar, element) {
    return pickedAutorizar.indexOf(element);
  }

  allSelectCardPend(event: any) {
    const tab = 'Pendientes';
    let selection = false;

    if (event.checked) {
      if (this.inputPendientes != '') {
        let arraySearch = [];
        for (let index = 0; index < this.ordenes.length; index++) {
          if (
            this.pipePendientes.transform(
              this.ordenes[index],
              this.inputPendientes
            ) == true
          ) {
            arraySearch.push(this.ordenes[index]);
            this.tapAutorizar[index] = true;
          }
        }

        this.pickedAutorizar = arraySearch;

        if (this.pickedAutorizar.length > 0) {
          this.pickedAutorizar = arraySearch;
          for (let i = 0; i < arraySearch.length; i++) {
            this.tapAutorizar[i] = true;
          }
        } else {
          for (let i = 0; i < arraySearch.length; i++) {
            this.tapAutorizar[i] = !this.tapAutorizar[i];
            this.pickedAutorizar.push(arraySearch[i]);
          }
        }
      } else {
        if (this.pickedAutorizar.length > 0) {
          this.pickedAutorizar = this.ordenes;
          for (let i = 0; i < this.ordenes.length; i++) {
            this.tapAutorizar[i] = true;
          }
        } else {
          for (let i = 0; i < this.ordenes.length; i++) {
            this.tapAutorizar[i] = !this.tapAutorizar[i];
            this.pickedAutorizar.push(this.ordenes[i]);
          }
        }
      }
      this.checkPendientes = true;
      selection = true;
    } else {
      this.pickedAutorizar = [];
      this.checkPendientes = false;
      this.tapAutorizar = [false];
    }

    this.resetTab(this.checkPendientes, selection, tab);
  }

  /* <<<<DESAUTORIZAR>>>> */
  openDesautorizar(): void {
    const dialogRef = this.dialog.open(DesautComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data == true) {
        if (screen.width < 800) {
          this.desautorizarMobile();
        } else {
          this.Desautorizar(this.selection);
        }
      }
    });
  }

  Desautorizar(selectionModel: SelectionModel<any>) {
    this.desautorizarResponse = [];
    for (let index = 0; index < selectionModel.selected.length; index++) {
      this.pagoService
        .desautorizarOrdenes(
          this.selectedCompany,
          selectionModel.selected[index]
        )
        .subscribe(
          (res) => {
            this.desautorizarResponse[index] = res.errortxt;
            if (index == selectionModel.selected.length - 1) {
              this.getOrdenesbyCompany();
              selectionModel.clear();
              this.responseModal(this.desautorizarResponse);
            }
          },
          (err) => {
            this.desautorizarResponse[index] = err.errortxt;
            if (index == selectionModel.selected.length - 1) {
              this.responseModal(this.desautorizarResponse);
            }
          }
        );
    }
  }

  desautorizarMobile() {
    this.desautorizarResponse = [];
    for (let index = 0; index < this.pickedDesautorizar.length; index++) {
      this.pagoService
        .desautorizarOrdenes(
          this.selectedCompany,
          this.pickedDesautorizar[index]
        )
        .subscribe(
          (res) => {
            this.desautorizarResponse[index] = res.errortxt;
            if (index == this.pickedDesautorizar.length - 1) {
              this.getOrdenesbyCompany();
              this.responseModal(this.desautorizarResponse);
            }
          },
          (err) => {
            this.desautorizarResponse[index] = err.errortxt;

            if (index == this.pickedDesautorizar.length - 1) {
              this.responseModal(this.desautorizarResponse);
            }
          }
        );
    }
    this.clearTab('Historial');
  }

  responseModal(response) {
    if (response) {
      this.dialogInitService
        .dialogInit(RespuestaComponent, response, dialogWidth, dialogHeight)
        .afterClosed()
        .subscribe((result) => {});
      this.checkRevierteOk();
    } else {
      this.snackBar.open(this.traducciones['errores'].error, 'X', {
        duration: 4000,
      });
    }
  }

  onSelectCardHist(index, item: any) {
    this.checkHistorial = false;
    let selection = true;
    const tab = 'Historial';

    if (item.revierteok == 'N') {
      this.disabledCard[index] = !this.disabledCard[index];
    } else {
      this.tapDesautorizar[index] = !this.tapDesautorizar[index];
      if (this.tapDesautorizar[index] == true) {
        this.pickedDesautorizar.push(item);
      } else {
        this.pickedDesautorizar.splice(
          this.arrayHistorial(this.pickedDesautorizar, item),
          1
        );
      }
    }

    if (this.pickedDesautorizar.length == 0) {
      selection = false;
    }

    this.resetTab(this.checkHistorial, selection, tab);
  }

  //elimina ordenes deseleccionadas
  arrayHistorial(pickedDesautorizar, element) {
    return pickedDesautorizar.indexOf(element);
  }

  allSelectCardHis(event: any) {
    let selection;
    const tab = 'Historial';

    if (event.checked) {
      if (this.inputHistorial != '') {
        let arraySearch = [];
        for (let index = 0; index < this.historial.length; index++) {
          if (
            this.pipeHistorial.transform(
              this.historial[index],
              this.inputHistorial
            ) == true
          ) {
            if (this.historial[index].revierteok == 'S') {
              arraySearch.push(this.historial[index]);
              this.tapDesautorizar[index] = true;
            } else {
              this.pickedDesautorizar.splice(
                this.arrayHistorial(this.historial, index),
                1
              );
              selection = false;
            }
          }
        }

        this.pickedDesautorizar = arraySearch;

        if (this.pickedDesautorizar.length > 0) {
          this.pickedDesautorizar = arraySearch;
          for (let i = 0; i < arraySearch.length; i++) {
            if (this.historial[i].revierteok == 'S') {
              this.tapDesautorizar[i] = true;
            }
          }
        } else {
          for (let i = 0; i < this.historial.length; i++) {
            if (this.historial[i].revierteok == 'S') {
              this.tapDesautorizar[i] = true;
              this.pickedDesautorizar.push(this.historial[i]);
              selection = true;
            } else {
              this.tapDesautorizar[i] = false;
              this.pickedDesautorizar.splice(
                this.arrayHistorial(this.historial, i),
                1
              );
              selection = false;
            }
          }
        }
      } else {
        if (this.pickedDesautorizar.length > 0) {
          this.pickedDesautorizar = this.historial;
          for (let i = 0; i < this.historial.length; i++) {
            if (this.historial[i].revierteok == 'S') {
              this.tapDesautorizar[i] = true;
            }
          }
        } else {
          for (let i = 0; i < this.historial.length; i++) {
            if (this.historial[i].revierteok == 'S') {
              this.tapDesautorizar[i] = true;
              this.pickedDesautorizar.push(this.historial[i]);
              selection = true;
            } else {
              this.tapDesautorizar[i] = false;
              this.pickedDesautorizar.splice(
                this.arrayHistorial(this.historial, i),
                1
              );
              selection = false;
            }
          }
        }
      }
      this.checkHistorial = true;
    } else {
      selection = false;
      this.checkHistorial = false;
      this.pickedDesautorizar = [];
      this.tapDesautorizar = [false];
    }

    this.resetTab(this.checkHistorial, selection, tab);
  }

  //show or hide button
  resetTab(check, selection, tab) {
    if (tab == 'Historial') {
      if (check == true || selection == true) {
        this.btnAutorizar = false;
        this.btnDesautorizar = true;
      } else {
        if (check == false && selection == false) {
          this.btnAutorizar = false;
          this.btnDesautorizar = false;
        }
      }
      for (let i = 0; i < this.sizeOrdenes; i++) {
        this.tapAutorizar[i] = false;
      }
      this.checkPendientes = false;
    } else {
      if (check == true || selection == true) {
        this.btnAutorizar = true;
        this.btnDesautorizar = false;
      } else {
        if (check == false && selection == false) {
          this.btnAutorizar = false;
          this.btnDesautorizar = false;
        }
      }
      for (let i = 0; i < this.sizeHistorial; i++) {
        this.tapDesautorizar[i] = false;
      }
      this.checkHistorial = false;
    }
  }

  isAllSelected(dataSource: any, selectionModel: SelectionModel<any>) {
    const numSelected = selectionModel.selected.length;
    const numRows = dataSource?.data.length;
    return numSelected === numRows;
  }

  isSomeSelected(selectionModel: SelectionModel<any>) {
    return selectionModel.selected.length > 0;
  }

  masterToggle(ref, selectionModel: SelectionModel<any>, dataSource: any) {
    // If there is a selection then clear that selection
    if (this.isSomeSelected(selectionModel)) {
      selectionModel.clear();
      ref.checked = false;
    } else {
      this.isAllSelected(dataSource, selectionModel)
        ? selectionModel.clear()
        : dataSource?.filteredData.forEach((row) => this.selection.select(row));
      ref.checked = false;
    }
  }

  //SEARCH TAB PENDIENTES
  applyFilterPend(filterValuePen) {
    this.filteredValuePen = filterValuePen;
    this.dataSourcePendientes.filter = filterValuePen.value
      .trim()
      .toLowerCase();

    if (filterValuePen.value == '') {
      this.selection.clear();
    }
  }

  //SEARCH TAB HISTORIAL
  applyFilterHist(filterValueHist) {
    this.filteredValueHist = filterValueHist;
    this.dataSourceHistorial.filter = filterValueHist.value
      .trim()
      .toLowerCase();

    if (filterValueHist.value == '') {
      this.selection.clear();
    }
  }

  // Filtro búsqueda para mobile

  inputPendientes: string;
  inputHistorial: string;

  onSearchHistorial(value: string) {
    this.inputHistorial = value;
    if (this.inputHistorial == '') {
      this.clearTab('Historial');
    }
  }

  onSearchPendientes(value: string) {
    this.inputPendientes = value;
    if (this.inputPendientes == '') {
      this.clearTab('Pendientes');
    }
  }

  //clear data tab
  clearTab(tab: String) {
    if (tab == 'Pendientes') {
      this.btnAutorizar = false;
      this.checkPendientes = false;
      this.tapAutorizar = [false];
    } else {
      this.btnDesautorizar = false;
      this.checkHistorial = false;
      this.tapDesautorizar = [false];
    }
  }

  tabChanged(event: any) {
    if (event) {
      this.checkRevierteOk();
      this.filterValueHist = null;
      this.dataSourceHistorial ? (this.dataSourceHistorial.filter = null) : '';
      this.filterValuePen = null;
      this.dataSourcePendientes
        ? (this.dataSourcePendientes.filter = null)
        : '';
      this.selection.clear();
    }
  }

  checkRevierteOk() {
    if (this.formGroup.value.empresa) {
      this.pagoService
        .getHistorial(this.formGroup.value.empresa)
        .subscribe((res) => {
          this.historial = res.opago;
          if (this.historial) {
            let disabled = 0;
            for (let i = 0; i < this.historial.length; i++) {
              if (this.historial[i].revierteok == 'N') {
                disabled++;
              }
            }
            if (disabled == this.historial.length) {
              this.revierteOk = false;
            } else {
              this.revierteOk = true;
            }
          }
        });
    }
  }

  //descargar la tabla en Excel Pendientes
  downloadExcelPendientes(): void {
    let cleanOutput = this.tableToXLSX_Pendientes.map(function (table) {
      return {
        Fecha: table.fecpago,
        Orden: table.ordpago,
        Beneficiario: table.beneficiario,
        Importe: table.importe,
        Firma: table.descfirma,
      };
    });
    this.xlsxService.exportAsExcelFile(cleanOutput, 'ordenes_pendientes');
  }

  //descargar la tabla en Excel Historial
  downloadExcelHistorial(): void {
    let cleanOutput = this.tableToXLSX_Historial.map(function (table) {
      return {
        Fecha: table.fecaut,
        Orden: table.ordpago,
        Beneficiario: table.beneficiario,
        Importe: table.aprobado,
        Firma: table.descfirma,
      };
    });
    this.xlsxService.exportAsExcelFile(cleanOutput, 'ordenes_historial');
  }

  generarTraducciones() {
    this.translateService
      .selectTranslateObject('facturas')
      .subscribe((traduccion) => {
        this.traducciones = traduccion;
      });
  }
}
