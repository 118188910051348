import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-concepto',
  templateUrl: './concepto.html',
  styleUrls: ['../nandu.component.scss']
})
export class ConceptoComponent implements OnInit {

  concepto: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { concepto: any },
    private dialogRef: MatDialogRef<ConceptoComponent>) {
    this.concepto = data.concepto;
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('35%', '35%');
  }

  guardar() {
    this.dialogRef.close({ data: true });
  }

}



