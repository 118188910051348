import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';

import * as moment from 'moment';

const MOSTRAR_CANCELADOS = true;

export class Notification {
    mensaje: string;
    createdAt: Date;
    empleado: {
        nombre: string;
        apellido: string;
        legajo: string;
    };
}

export class News {
    titulo: string;
    descripcion: string;
    createdAt: Date;
    empresa: { nombre: string };
    empleado: { nombre: string; };
}

export class Pendiente {
    beneficiario: string;
    fecpago: Date;
    firma: string;
    ordpago: number;
    importe: string;
}

export class Historial {
    aprobado: string;
    beneficiario: string;
    fecaut: Date;
    firma: string;
    ordpago: number;
}




@Component({
    selector: 'search-filter-component',
    templateUrl: './search-filter.component.html'
})
export class SearchFilterComponent implements OnInit {

    searchInput: string = '';
    @Output() inputValue = new EventEmitter<string>();

    constructor(public searchFilterService: SharedSearchFilterService) { }

    ngOnInit() {

        this.inputValue.emit(this.searchInput);
    }

    clearFilter(): void {

        this.searchInput = '';
        this.inputValue.emit(this.searchInput);
    }

    onKeyUp(input: string): void {

        this.searchInput = this.searchFilterService.applyFilter(input);
        this.inputValue.emit(this.searchInput);
    }
}

@Injectable()
export class SharedSearchFilterService {

    // Lógica del filtro de búsqueda para el formulario de Notificaciones (usuario regular)
    notificationsFilter(notification: Notification, input: string) {

        if (notification.mensaje) {
            if (
                this.lowerCaseNormalizedAndTrimmedIncludes(notification.mensaje, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(this.extractDate(notification.createdAt), input)
            ) {
                return true;
            } else
                return false;
        } else
            return false;

    }

    // Lógica del filtro de búsqueda para el formulario de Notificaciones (usuario admin)
    notificationsAdminFilter(notification: Notification, input: string) {

        if (notification.mensaje) {
            if (
                this.lowerCaseNormalizedAndTrimmedIncludes(notification.mensaje, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(this.extractDate(notification.createdAt), input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(notification.empleado.nombre, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(notification.empleado.apellido, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(notification.empleado.legajo, input)
            ) {
                return true;
            } else
                return false;
        } else
            return false;
    }

    // Lógica del filtro de búsqueda para el formulario de Noticias (usuario regular)
    newsFilter(news: News, input: string) {

        if (news.titulo) {
            if (
                this.lowerCaseNormalizedAndTrimmedIncludes(news.titulo, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(news.descripcion, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(this.extractDate(news.createdAt), input)
            ) {
                return true;
            } else
                return false;
        } else
            return false;
    }

    // Lógica del filtro de búsqueda para el formulario de Noticias (usuario admin)
    newsAdminFilter(news: News, input: string) {

        if (news.titulo) {
            if (
                this.lowerCaseNormalizedAndTrimmedIncludes(news.titulo, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(news.descripcion, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(('Fecha de envío' + ' ' + this.extractDate(news.createdAt)), input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(((news.empresa ? 'Empresa' + ' ' + news.empresa.nombre : '')), input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(((news.empleado ? 'Empleado' + ' ' + news.empleado.nombre : 'Todos los empleados')), input)
            ) {
                return true;
            } else
                return false;
        } else
            return false;
    }

    //filtro de busqueda en tablas para mobile
    pendientesFilter(ordenes: Pendiente, input: string) {
        if (ordenes) {
            if (
                this.lowerCaseNormalizedAndTrimmedIncludes(ordenes.beneficiario, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes((this.extractDate(ordenes.fecpago)), input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(ordenes.firma, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(('Orden' + ' ' + (ordenes.ordpago ? ordenes.ordpago : '')), input)
            ) {
                return true;
            } else
                return false;
        } else
            return false;
    }

    historialFilter(ordenes: Historial, input: string) {
        if (ordenes) {
            if (
                this.lowerCaseNormalizedAndTrimmedIncludes(ordenes.aprobado, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(ordenes.beneficiario, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes((this.extractDate(ordenes.fecaut)), input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(ordenes.firma, input) ||
                this.lowerCaseNormalizedAndTrimmedIncludes(('Orden' + ' ' + (ordenes.ordpago ? ordenes.ordpago : '')), input)
            ) {
                return true;
            } else
                return false;
        } else
            return false;
    }



    // Definido en la constante MOSTRAR_CANCELADOS, permite o no mostrar ítems con estado "CANCELADO"
    showCancelled(status: string) {

        if (MOSTRAR_CANCELADOS) {
            return true;
        } else {
            if (status !== 'CANCELADO') {
                return true;
            } else {
                return false;
            }
        }
    }

    // Almaceno el filtro de la búsqueda que se ingresó de manera correcta
    applyFilter(filterValue: string) {

        if (filterValue) return filterValue;
        else return ''; // Si no devuelvo un string vacío, el filtro se asigna como undefined, impidiendo que filtre
    }

    // Convierte números en strings para poderlos comparar cuando se mezclan con texto
    compareStringToNumber(input: number, string: string) {
        return (input + '').indexOf(string) > -1;
    }

    // Se deshace de espacios al final del texto, mayúsculas y tildes para poder comparar los textos crudos
    lowerCaseNormalizedAndTrimmedIncludes(value: string, input: string) {

        let normalizedValue = this.normalizeString(value ? value : '').toLowerCase();
        let normalizedInput = this.normalizeString(input).toLowerCase();

        return normalizedValue.includes(normalizedInput.trim());
    }

    normalizeString(input: string) {

        if (input.length > 0)
            return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        else
            return ''
    }

    // Extrae [día, mes y año] de las fechas en formato {unix timestamp}
    extractDate(date: Date /*, order: any */) {

        var fullDate = moment(date, 'YYYY/MM/DD');

        var day = fullDate.format('D');
        // Si el día es menor a 10, le agrego un cero a la izquierda
        day = (parseInt(day) < 10 ? '0' : '') + day;

        var month = fullDate.format('M');
        // Si el mes es menor a 10, le agrego un cero a la izquierda
        month = (parseInt(month) < 10 ? '0' : '') + month;

        var year = fullDate.format('YYYY');

        /*
            switch (order) {
                case 'day': { return day; }
                case 'month': { return month; }
                case 'year': { return year; }
            }
        */

        return (day + '/' + month + '/' + year);
    }
}